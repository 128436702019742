.logs-list {
    &__filters {
        position: sticky;
        top: 0;
        padding-top: 10px;
        z-index: 10;
        background: white;
    }

    &__filters-container {
        display: flex;
        flex-wrap: nowrap;
        gap: 30px;
        overflow-x: auto;
    }

    &__filter-item {
        min-width: 400px;
    }
}
