.sticky-action-banner {
    &__spacer {
        padding-top: 80px;
    }

    &__banner {
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 20px;
        background: #f0f2f5;
    }
}
