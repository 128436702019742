.active {
    background-color: var(--layout-background-color);
    .footer {
        .tag {
            background-color: var(--outline-focused-color);
        }
    }
}

.card {
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid var(--layout-background-color);
    border-radius: 5px;
    cursor: pointer;
    position: relative;

    &.selected {
        @extend .active;
        border-color: var(--primary-color);
    }

    &:hover {
        @extend .active;
    }

    .title {
        padding-right: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
    }

    .desc {
        font-size: 11px;
        line-height: 12px;
    }

    .footer {
        display: flex;
        flex-direction: row;
        margin-top: 4px;

        .tag {
            display: flex;
            font-size: 12px;
            background-color: var(--layout-background-color);
            border-radius: 10px;
            padding: 0 8px;
            margin: 0;
            align-items: center;
        }
    }

    &:hover {
        .delete {
            display: block;
        }

        .duplicate {
            display: block;
        }
    }

    .delete {
        display: none;
        position: absolute;
        top: 0px;
        right: 5px;
        padding: 5px;
        cursor: pointer;
    }

    .duplicate {
        display: none;
        position: absolute;
        top: 25px;
        right: 5px;
        padding: 5px;
        cursor: pointer;
    }

    .children {
        padding: 8px 0 5px;
    }

    .times {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 5px;
        padding-right: 18px;

        .date {
            display: flex;
            font-size: 11px;
        }
    }
}
