.login {
    @media screen and (max-width: 768px) {
        height: 100vh;

        .row {
            position: relative;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;

            .col {
                width: 100%;
            }
        }
    }

    .fakeHeight {
        height: 100vh;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .formCard {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
        padding: 60px 40px;
        border-radius: 10px;
        max-width: 450px;
        margin: 0 auto;

        .formLogo {
            text-align: center;
        }

        @media screen and (max-width: 768px) {
            top: inherit;
            transform: inherit;
            margin: 10px;
            padding: 60px 25px;
        }

        .form {
            margin-top: 40px;

            .btn {
                margin-top: 5px;
                width: 100%;
                font-family: "Roboto Mono", monospace;
                font-weight: bold;
            }
        }
    }
}
